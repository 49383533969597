<script lang="ts">
export default {
    name: 'GlobalInfo',
    props: {
        data: { type: Object, required: true },
        isForm: { type: Boolean, default: false },
        validationRules: { type: Object, required: false },
        validation: { type: Object, required: false },
        translationName: { type: String, required: true },
        hideSection : { type : Boolean, required : false}
    },
    emits: ['updateData'],
    methods : {
        isValidated () {
            this.$refs.first_name?.triggerValidation()
            this.$refs.last_name?.triggerValidation()
            this.$refs.email?.triggerValidation()
            this.$refs.organization_id?.triggerValidation()
        }
    }
}
</script>

<template>
    <FormSection title="Informations générales" with-borders-color="primary" :hideSection>
        <FormInput 
            ref="first_name"
            data-key="first_name"
            :validation="validation?.data.first_name"
            :data="data"
            :validation-rules="validationRules"
            :translation-name="translationName"
            @update-data="$emit('updateData', { [`first_name`]: $event })"
        />
        <FormInput 
            ref="last_name"
            data-key="last_name"
            :validation="validation?.data.last_name"
            :data="data"
            :validation-rules="validationRules"
            :translation-name="translationName"
            @update-data="$emit('updateData', { [`last_name`]: $event })"
        />
        <FormInput 
            ref="email"
            data-key="email"
            :text-tooltip="isForm ? $translate(`${translationName}.email.tooltip`) : undefined"
            :validation="validation?.data.email"
            :data="data"
            :validation-rules="validationRules"
            :translation-name="translationName"
            @update-data="$emit('updateData', { [`email`]: $event })"
        />
        <FormAutocomplete 
            ref="organization_id"
            data-key="organization"
            :validation="validation?.data.organization_id"
            validation-key="organization_id"
            :validation-rules="validationRules"
            :data="data"
            :translation-name="translationName"
            @update-data="$emit('updateData', { [`organization`]: $event, [`organization_id`]: $event?.id || null })"
        />
        <slot name="inputs" />
    </FormSection>
</template>
